@import "~assets/styles/palette.scss";
@import "~assets/styles/mixins.scss";

.wrapper {
  @include flexbox();
  flex-direction: column;

  padding: 40px 44px 40px;
}

.logo {
  align-self: flex-start;
  width: 50px;
  height: 40px;
}

.title {
  font-size: 30px;
  margin-top: 24px;
}

.button {
  margin-top: 24px;
  width: 100%;

  &.email {
    background-color: $blue1;
  }
}

.google_button {
  margin-top: 24px;
}

.label_wrapper {
  @include flexbox();

  margin-top: 24px;
}

.icon {
  margin-right: 12px;
}

.label {
  font-size: 14px;
  font-weight: 500;
}

.log_in {
  font-family: $gilroy;
  color: $primary;
  margin-left: 8px;
}
