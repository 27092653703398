@import "~assets/styles/palette.scss";
@import "~assets/styles/mixins.scss";
@import "~assets/styles/fonts.scss";

.h1 {
  font-family: $gilroy;
  font-weight: 800;
  font-size: 172px;
  line-height: 110%;
  color: $white;
  text-align: center;
  background-clip: text;
  text-transform: uppercase;
}

.h2 {
  font-family: $gilroy;
  font-weight: 800;
  font-size: 48px;
  line-height: 110%;
  margin: 0;
  color: $white;
  text-transform: uppercase;
  
  @include mobile {
    font-size: 24px;
  }
}

.h3 {
  font-family: $gilroy;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  margin: 0;
  color: $white;
  
  @include mobile {
    font-size: 24px;
  }
}

.h4 {
  font-family: $gilroy;
  font-weight: 600;
  font-size: 28px;
  line-height: 110%;
  color: $white;    
}

.h5 {
  font-family: $gilroy;
  font-weight: 500;
  font-size: 30px;
  line-height: 140%;
  color: $white;    
}

.span {
  font-family: $gilroy;
  font-size: 24px;
  color: $white;
  line-height: 32px;
  font-weight: 600;
}

.p {
  font-family: $gilroy;
  color: $white;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  margin: 0;

  strong {
    font-weight: inherit;    
  }
}
