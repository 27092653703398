@import "~assets/styles/palette.scss";
@import "~assets/styles/mixins.scss";
@import "~assets/styles/fonts.scss";


.body {
  overflow: hidden;
}

.modal {
  position: relative;
  

  border-radius: 30px;
  flex-direction: column;
  background-color: $darkGrey;
  box-sizing: border-box;
  border: none;

  overflow: hidden;

  &:focus {
    outline: none;
  }

  .modal_close_button {
    position: absolute;
    top: 45px;
    right: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  overflow: auto;


  @include flexbox;

  @include can_backdrop {
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
  }
}
