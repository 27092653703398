@import '~assets/styles/mixins';
@import '~assets/styles/variables';
@import '~assets/styles/palette';
@import '~assets/styles/fonts';

.footer_container {
  margin-top: auto;
  height: $footerHeight;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 80px 1fr;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .footer_left {
    display: flex;
    flex-direction: column;

    .footer_links_line_first {
      display: flex;
      flex-direction: row;

      .footer_link_margin_right {
        margin-right: 55px;
      }
    }

    .footer_links_line_second {
      margin-top: 16px;
      display: flex;
      flex-direction: row;      

      .footer_links_line_second_margin {
        margin-right: 16px;
      }
    }
  }
  .footer_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .footer_copyright_text {
      font-size: 16px;
      line-height: 22px;
      color: $grey;
    }
  }  
}