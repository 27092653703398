@import "~assets/styles/palette.scss";
@import "~assets/styles/mixins.scss";
@import "~assets/styles/fonts.scss";

.verification_input_styles {
  --ReactInputVerificationCode-itemWidth: 46px;
  --ReactInputVerificationCode-itemHeight: 44px;
}

.verification_input_styles .ReactInputVerificationCode__item {
  position: relative;
  color: $white;
  font-family: $roboto;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;

  justify-content: center;
  align-items: center;
  background-color: $black;
  display: flex;
  transition: border-color 0.2s;
  border: 2px solid transparent;
}

.verification_input_styles .ReactInputVerificationCode__item,
.verification_input_styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.verification_input_styles .ReactInputVerificationCode__item.is-active {
  border-color: $primary;
}

