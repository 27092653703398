@import 'assets/styles/mixins';
@import '~assets/styles/variables';
@import '~assets/styles/palette';
@import '~assets/styles/fonts';

.wrapper {
  background-color: $pop-over;
}

.title_wrapper {
  @include flexbox(center, flex-start);
}

.icon {
  margin-right: 16px;
  width: 22px;

  &.success {
    color: $polar-green-6;
  }

  &.error {
    color: $dust-red-5;
  }

  &.info {
    color: $calendula-gold-5;
  }
}

.title {
  font-size: 16px;
}

.message {
  font-size: 14px;
}
