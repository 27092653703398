@import "~assets/styles/mixins";
@import "~assets/styles/variables";
@import "~assets/styles/palette";
@import "~assets/styles/fonts";

.steps_outer_container {
padding: 80px 0 0 0;
width: 100%;
}

.step_outer_container {
  font-family: $gilroy;
  font-style: normal;
}

.step__head__h2 {
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  color: $white;
  margin: 16px;
  text-transform: none;
}

.step__head__h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: $white;
  text-align: center;
}

.steps__container {
  @include flexbox(center, center);

  flex-direction: column;
  padding: 32px 0 0 0;
  font-family: $gilroy;
  color: $white;
  font-style: normal;
  width: 100%;
}

.step_outer_container {
  margin: 0 0 24px;
  width: 100%;

  &:nth-last-child() {
    margin: 0 0 32px;
  }
}

.step__buttons {
  @include flexbox(center, center);

  & .step__cancel {
    margin: 0 0 0 16px;
    color: $grey;
    border: 1px solid $grey;
  }
}


.step__submit,
.step__cancel {
  padding: 19px 34px;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  width: 164px;
}
