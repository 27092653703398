@import 'assets/styles/mixins';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.main_layout__container {
  width: 100%;
  min-height: 100%;
  padding-top: $headerHeight;
  background: $backgroundColor;

  background-image: url('~assets/images/backgroundImage.svg');
  background-size:     cover;                      
  background-repeat:   no-repeat;
  background-position: top 0px left 0px;

  display: flex;
  flex-direction: column;  
  align-items: center;
}

.mainContainer {
  max-width: $layoutWidth;
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
 }


