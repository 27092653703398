
@import '~assets/styles/palette';

.check_box_container {

  display: flex;
  flex-direction: row;  
  align-items: center;

  .check_box_description {
    margin-left: 8px;
    color: $white2;
    font-size: 14px;
    line-height: 22px;
  }

  .disabled {
    color: $disabled;
  }
}
