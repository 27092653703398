@import "~assets/styles/mixins";
@import "~assets/styles/variables";
@import "~assets/styles/palette";
@import "~assets/styles/fonts";

.welcom__container {
  @include flexbox(center, center);

  flex-direction: column;

  padding: 0 0 48px 0;
}

.welcom {
  font-weight: 800;
  font-size: 48px;
  line-height: 110%;
  text-align: center;
  text-transform: uppercase;
  color: $white;
}

.welcom__head {
  font-weight: 800;
  font-size: 172px;
  line-height: 110%;
  color: $white;
  text-align: center;
  text-transform: uppercase;
}

.welcom__description {
  font-weight: 500;
  font-size: 30px;
  line-height: 140%;
  text-align: center;
  color: $white;
  text-transform: none;
}

