@import 'assets/styles/palette.scss';
@import 'assets/styles/fonts.scss';

.label_container {

  .contact_item_label {
    color: $grey;
    font-size: 16px;
    line-height: 22px;
  }

}
