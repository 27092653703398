@import "~assets/styles/mixins";
@import "~assets/styles/variables";
@import "~assets/styles/palette";
@import "~assets/styles/fonts";

.carousel_outer_container {

  position: relative;
  min-width: 0;
  max-width: $layoutWidth/2 - 50;
  overflow: hidden;
  width: 100%;

  :global {
    .swiper-wraper,
    .swiper-slide {
      width: auto;
      min-width: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }
}


.arrow {
  color: $grey;
}

.line {
  opacity: 0.5;
  border: 2px solid $grey;
}

.card__read__container {
  padding: 24px 0 0 0;
}

.pagination {
  text-align: center;
  bottom: 10px;
  left: 41.5%;
  min-width: 100px;
}

.carousel__buttons {
  @include flexbox(center, space-between);

  margin: 0 auto;
  padding: 24px 0 0 0;
  max-width: 200px;

  & .button__arrow {
    cursor: pointer;
    border-radius: 10px;
    width: 44px;
    height: 44px;
    background: $darkGrey;
    color: $grey;
  }
}

.bullet {
  cursor: pointer;
  display: inline-block;
  margin: 4px 6px;
  width: 8px;
  height: 8px;
  background-color: $grey;
  border-radius: 4px;

  &.active {
    background: $primary;
    opacity: 1;
  }
}
