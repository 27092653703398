@import './styles/palette';
@import './styles/variables';
@import './styles/fonts';

:root {
  --color-white: #ffffff;
  --color-black: #092326;

  &.theme_dark {
    --color-background: var(--color-black);
    --color-text: var(--color-white);
  }

  &.theme_light {
    --color-background: var(--color-white);
    --color-text: var(--color-black);
  }
}

html,
body,
div[id="root"] {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  border: 0;

  .Toastify__toast {
    min-height: auto;
    padding: 15px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: none;
    background-color: $pop-over;
  }
}

body {
  color: var(--color-text);
  background-color: var(--color-background);
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

*,
::after,
::before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

p {
  margin: 0;
}

ul, ol {
  margin: 0;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

button {
  box-shadow: none;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: inherit;
}
