@import "~assets/styles/mixins";
@import "~assets/styles/variables";
@import "~assets/styles/palette";
@import "~assets/styles/fonts";

.step__container {
  @include flexbox(center, space-between);

  padding: 25px 30px;
  font-family: $gilroy;
  color: $white;
  font-style: normal;
  background: linear-gradient(170.57deg, #393b55 11.49%, #323450 92.88%);
  border-radius: 30px;
}

.step__text {
  @include flexbox(center, space-between);

  flex: 1;
  & .step__description {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
  }

  & .step__checkbox {
    border: 1px solid $grey;
    border-radius: 2px;
    width: 16px;
    height: 16px;
  }

  & .step__checkbox__description {
    color: $disabled;
  }
}

.step__order {
  font-weight: 800;
  font-size: 36px;
  line-height: 140%;
  color: $white;
  margin: 0 33px 0 0;
}

.step__icon__question {
  margin: 0 0 0 34px;
}

.step__text__withQuestion {
  @include flexbox(center, center);
}

.step__button {
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  margin: 0 0 0 24px;
}

