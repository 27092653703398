@import "~assets/styles/palette.scss";
@import "~assets/styles/mixins.scss";
@import "~assets/styles/fonts.scss";


.login_modal_container {
  margin-top: 24px;

  display: grid;
  grid-template-rows: auto;
  row-gap: 24px;

  .login_modal_title {
    font-size: 30px;
    text-align: center;
  }

  .login_modal_buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .login_modal_button {
      width: 172px;
    }
  }

  .login_modal_bottom_line_container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .login_modal_question_text {
      margin-right: 8px;
      color: $grey;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
