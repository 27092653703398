@import "~assets/styles/palette.scss";
@import "~assets/styles/mixins.scss";

.wrapper {
  position: relative;
}

.menu {
  @include flexbox(flex-start, center);
  flex-direction: column;

  background: $darkGrey;
  padding: 13px;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: transform .2s,opacity .2s,visibility 0s linear .2s;
  will-change: transform;
  position: absolute;
  width: fit-content;
  left: 0;
  margin-top: 20px;
  z-index: 2;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.item {
  white-space: nowrap;
}
