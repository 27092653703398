@import "~assets/styles/mixins";
@import "~assets/styles/variables";
@import "~assets/styles/palette";
@import "~assets/styles/fonts";

.card_outer_container {
  @include flexbox(flex-start, space-between);

  flex-direction: column;

  font-family: $gilroy;
  position: relative;
  font-style: normal;
  color: $white;
  // background: linear-gradient(170.57deg, #393b55 11.49%, #323450 92.88%);
  border-radius: 30px;
  padding: 40px;
  width: 100%;
  max-width: 294px;
  background-size: cover;
}

.line {
  opacity: 0.5;
  border: 2px solid $grey;
  width: 100%;
}

.card__read__container {
  @include flexbox(center, space-between);

  padding: 24px 0 0 0;
  width: 100%;
}

.card__read__more {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;

  display: flex;
  align-items: center;
}

.card__content {
  @include flexbox(flex-start, space-between);

  flex-direction: column;
  flex: 1 1 170px;
  padding: 0 0 24px 0;
}

.background_image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
