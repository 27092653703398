$backgroundColor: #1B1E2C;

$primary: #8F7CFF;

$white: #FFFFFF;
$white2:#ffffffd9;

$black: #000000;

$darkBlue: #1B1E2D;

$grey: #A6B1D6;
$darkGrey: #34374F;

$disabled: #68697E;
$pop-over: #222626;
$polar-green-6: #52C41A;
$dust-red-4: #FF7875;
$dust-red-5: #FF4D4F;
$calendula-gold-5: #FFC53D;
$blue1: #2F80ED;
$dust-red-9: #DB4A39;
