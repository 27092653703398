@import './variables';

$defaultFlexBoxAlign: center;
$defaultFlexBoxJustify: center;

@mixin flexbox($align: $defaultFlexBoxAlign, $justify: $defaultFlexBoxJustify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin can_backdrop {
  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    @content;
  }
}


@mixin blur($radius: 5px) {
  -webkit-filter: blur($radius);
  -moz-filter: blur($radius);
  -o-filter: blur($radius);
  -ms-filter: blur($radius);
  filter: blur($radius);
}

@mixin hidden() {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}

@mixin ellipsis($max-width) {
  max-width: $max-width;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@keyframes appear {
  from {
    width: 0;
  }

  to {
    opacity: 1;
  }
}

// Media request mixins MobileFirst
@mixin mobile {
  @media screen and (max-width: 576px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin desktop_lg {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin desktop_xl {
  @media screen and (max-width: 1400px) {
    @content;
  }
}
