@import '~assets/styles/mixins';
@import '~assets/styles/variables';
@import '~assets/styles/palette';
@import '~assets/styles/fonts';

.button {
  @include flexbox(center, center);

  padding: 0px 24px;
  border-radius: 25.5px;
  box-sizing: border-box;

  font-family: $gilroy;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: $white;

  transition: all $transition-time;
  white-space: nowrap;  

  &:focus {
    outline: none;
  }

  &.primary {
    height: 56px;
    background: $primary;
    
    &:disabled {
      background: $darkBlue;
      color: $darkGrey;
    }
  }

  &.outline {
    height: 56px;
    background-color: transparent;
    border: 1px solid $white;

    &:disabled {
      background: $darkBlue;
      color: $darkGrey;
    }
  }

  &.small {
    height: 40px;
    padding: 0px 16px;
    font-size: 14px;
  }

  
  &.secondary {
    background: $darkGrey;
    color: $grey;
    border-radius: 68px;

    
    &:disabled {
      background: $darkBlue;
      color: $darkGrey;
    }
  }


  &.arrow {
    background-color: transparent;
    color: $white;
    padding: 0;


    &:disabled {
      background: $darkBlue;
      color: $darkGrey;
    }

  }

  
  &.text {
    background-color: transparent;
    padding: 0;
    color: $primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: normal;

    &:disabled {
      color: $darkGrey;
    }
  }  

  &.full_width {
    width: 100%;
  }
}