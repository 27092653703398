@import "~assets/styles/palette.scss";
@import "~assets/styles/mixins.scss";
@import "~assets/styles/fonts.scss";


.modal_content_wrapper_container {

  display: grid;
  width: 514px + 496px;
  grid-template-columns: 514px 496px;

  .modal_content_wrapper_left_side {
    display: flex;

    background-image: url('~assets/images/modalImage.png');
    background-size: auto 100%;
    background-repeat:   no-repeat;
    background-position: 50% 50%;

  }

  .modal_content_wrapper_right_side {
    padding: 64px 68px 40px;
    position: relative;

    display: flex;
    flex-direction: column;

    .modal_content_wrapper_logo {
      width: 50px;
      height: 40px;
    }
  }
}