@import "~assets/styles/mixins";
@import "~assets/styles/variables";
@import "~assets/styles/palette";
@import "~assets/styles/fonts";

.carousels_outer_container {
  @include flexbox(center, space-between);

  width: 100%;
  min-width: 0;
}

.carousel__container__left {
  @include flexbox(flex-start, center);

  flex-direction: column;

  width: 100%;
  min-width: 0;
}

.carousel__container__right {
  @include flexbox(flex-end, center);

  flex-direction: column;

  width: 100%;
  min-width: 0;
}

.carousels__text {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.carousels__date {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: $grey;
}

.carousel__head {
  font-weight: 600;
  font-size: 28px;
  line-height: 110%;
  color: $white;
  margin: 0 auto 32px auto;
}

.carousels_card {
  background: linear-gradient(170.57deg, #393b55 11.49%, #323450 92.88%);
  border-radius: 30px;
}

.carousels_card_news {
  background: linear-gradient(170.57deg, #393b55 11.49%, #323450 92.88%);
  border-radius: 30px;
}
