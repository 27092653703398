@import '~assets/styles/mixins';
@import '~assets/styles/variables';
@import '~assets/styles/palette';
@import '~assets/styles/fonts';

.header_container {
  position: fixed;
  z-index: 2;
  top: 0;
  left:0;
  display: flex;
  width: 100%;
  height: $headerHeight;

  justify-content: center;

  .header_content {
    max-width: $layoutWidth;
    width: 100%;
    flex-direction: row;
    align-items: center;
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
    position: relative;

    .header_logo {
      margin-left: calc(50% - 25px);
      margin-right: 20px;
    }

    .header_buttons_container {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      button:first-child {
        margin-right: 16px;
      }

      img {
        margin-right: 10px;
      }

      .three_points {
        margin-right: 24px;
        margin-left: 10px;
      }
    }
  }

  .avatar {
    border-radius: 100%;
    max-width: 24px;
    max-height: 24px;
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }

  .no_avatar {
    background: linear-gradient(180deg, #E99EFF 0%, #987FFF 100%);
  }

  .user_dropdown {
    @include flexbox();
  }

  .user_dropdown_text {
    font-size: 14px;
    white-space: nowrap;
  }

  .dropdown_item {
    @include flexbox();

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .dropdown_item_text {
    white-space: nowrap;
    font-size: 14px;
  }

  .connect_label {
    font-size: 12px;
  }
}

.header_scrolled {
  background: linear-gradient(180deg, #1A1B2E 49.93%, rgba(26, 27, 46, 0) 100%);
}

.red {
  color: $dust-red-4;
}

.grey {
  color: $grey
}

.wallet_address_wrapper {
  @include flexbox()
}

.wallet_address {
  font-size: 16px;
}
