@font-face {
  font-family: 'Gilroy';
  src: url('~assets/fonts/Gilroy-Regular.eot');
  src: local('Proxima Nova Regular'), local('~assets/fonts/Gilroy-Regular'),
      url('~assets/fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
      url('~assets/fonts/Gilroy-Regular.woff2') format('woff2'),
      url('~assets/fonts/Gilroy-Regular.woff') format('woff'),
      url('~assets/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('~assets/fonts/Gilroy-ExtraBold.woff') format('woff'),
    url('~assets/fonts/Gilroy-ExtraBold.eot') format('eot'),
    url('~assets/fonts/Gilroy-ExtraBold.ttf') format('ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('~assets/fonts/Gilroy-Bold.woff') format('woff'),
    url('~assets/fonts/Gilroy-Bold.eot') format('eot'),
    url('~assets/fonts/Gilroy-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('~assets/fonts/Gilroy-SemiBold.woff') format('woff'),
    url('~assets/fonts/Gilroy-SemiBold.eot') format('eot'),
    url('~assets/fonts/Gilroy-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
}
