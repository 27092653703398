@import "~assets/styles/palette.scss";
@import "~assets/styles/mixins.scss";

.wrapper {
  @include flexbox();
  flex-direction: column;

  padding: 40px 44px 40px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  margin-top: 14px;
}

.logo {
  align-self: flex-start;
  width: 50px;
  height: 40px;
}

.sub_title {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
}

.input {
  margin-top: 16px;
}

.buttons {
  @include flexbox();

  margin-top: 24px;
  width: 100%;
}

.button {
  width: 100%;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.verification_container {
  margin-top: 24px;
}

.verification_description {
  font-size: 14px;
  font-weight: 500;
  max-width: 356px;
  margin-bottom: 8px;
}

.passwords {
  margin-top: 24px;
}

.password {
  &:not(:first-child) {
    margin-top: 24px;
  }
}
