@import "~assets/styles/palette.scss";
@import "~assets/styles/mixins.scss";
@import "~assets/styles/fonts.scss";


.register_modal_container {
  margin-top: 24px;

  display: grid;
  grid-template-rows: auto;
  row-gap: 24px;

  .register_modal_title {
    font-size: 30px;
    text-align: left;
    white-space: nowrap;
  }

  .register_modal_verification_container {
    height: 92px;
    display: flex;
    flex-direction: column;

    .register_modal_verification_description {
      margin-bottom: 8px;
      font-size: 14px;      
      max-width: 328px;
    }
  }

  .register_modal_buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    max-width: 360px;

    .register_modal_button {
      width: 172px;
    }
  }

  .register_modal_bottom_line_container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .register_modal_question_text {
      margin-right: 8px;
      color: $grey;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
