@import "~assets/styles/mixins";
@import "~assets/styles/palette";
@import "~assets/styles/fonts";

.input__container {
  width: 360px;

  .input__label {
    font-size: 16px;
    padding-bottom: 1px;
    font-family: $gilroy;

    width: 100%;
    justify-content: flex-start;
    text-align: left;
  }
  

  .input__box {
    overflow: hidden;    
    border-radius: 8px;
    background: $black;
    height: 40px;    

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }
    
    input {
      grid-area: input;
      background: $black;
      width: 100%;
      border-radius: 8px;
      border: 0;
      outline: 0;
      padding: 8px 0 8px 12px;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      font-family: $roboto;
      color: $white;      
    
      ::placeholder {
        color: $disabled;
      }
    }

    .input__icon {
      grid-area: icon;
      display: grid;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
      
    }
  }
  .input__withIcon {
    display: grid;
    grid-template-columns: 1fr 42px;
    grid-template-areas: 'input icon';
  }
  
}