@import "~assets/styles/palette.scss";
@import "~assets/styles/mixins.scss";
@import "~assets/styles/fonts.scss";

.verification_container {
  margin-top: 24px;
}

.verification_description {
  font-size: 14px;
  font-weight: 500;
  max-width: 356px;
  margin-bottom: 8px;
}

