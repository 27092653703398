@import "~assets/styles/palette.scss";

button[type='button'] {
  &.button {
    width: 100%;
    background-color: $dust-red-9;
  }

  .icon {
    margin-right: 12px;
  }
}
